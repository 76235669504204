/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
}

/* Remove list styles on ul and ol elements */
ul,
ol {
	list-style: none;
}

/* Set core root styles */
html {
	height: 100%;
	width: 100%;

	scroll-behavior: smooth;
	text-size-adjust: 100%;
}

body {
	height: 100%;
	width: 100%;

	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	line-height: 1.5;
}

#root {
	height: 100%;
	width: 100%;
}

/* Remove text-decoration on links */
a {
	text-decoration: none;
}

/* Set border-box box-sizing globally */
html {
	box-sizing: border-box;
}

/* Set responsive image rules */
img {
	max-width: 100%;
	height: auto;
}
